<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 pb-12 pt-4 lg:px-8"
  >
    <div
      v-if="isFirebaseUserLoading && !fromAction == 'loginPage'"
      class="w-full flex justify-center"
    >
      <span class="loading loading-spinner loading-lg" />
    </div>
    <div
      v-if="fromAction == 'loginPage'"
      class="w-full flex justify-center py-10"
    >
      <NuxtLink to="/">
        <img class="h-12 w-21" src="/logo.png" alt="Logo" />
      </NuxtLink>
    </div>
    <div
      v-if="!fromAction || fromAction == 'loginPage'"
      class="sm:mx-auto sm:w-full sm:max-w-sm"
    >
      <h2
        class="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Welcome to MyProviders
      </h2>
    </div>
    <div
      v-if="fromAction && fromAction !== 'loginPage'"
      class="sm:mx-auto sm:w-full sm:max-w-sm"
    >
      <UserCircleIcon class="w-14 h-14 mx-auto text-gray-400" />
      <h2
        class="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        You're almost done!
      </h2>

      <p v-if="fromAction === 'review'" class="text-sm mt-3 text-center">
        Verify your identify by logging in.
      </p>
    </div>

    <div
      v-if="loginMessage"
      class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm border px-4 py-3 rounded relative"
      role="alert"
      :class="{
        'text-red-700 border-red-400 bg-red-100': loginMessage.type === 'error',
        'text-blue-700 border-blue-400 bg-blue-100':
          loginMessage.type === 'info',
      }"
    >
      {{ loginMessage.message }}
    </div>

    <div
      v-if="mode == 'signup'"
      class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm space-y-3"
    >
      <p class="text-xs text-center mb-10">
        By proceeding, you agree to the
        <a href="/terms" class="underline" target="_blank">terms</a> and
        <a href="/privacy" class="underline" target="_blank">privacy policy</a>
      </p>
      <!-- <button @click="handleFacebookLogin" type="submit" class="flex w-full justify-center items-center rounded-md px-5 py-1.5 text-sm font-medium leading-6 shadow-s border hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <img src="~/assets/icons/facebook.svg" alt="Facebook Logo" class="w-5 h-5 mr-2" />
        Continue with Facebook
      </button> -->
      <button
        type="submit"
        class="btn btn-outline w-full hover:bg-gray-200 hover:text-black"
        @click="handleGoogleLogin"
      >
        <img
          src="~/assets/icons/google.svg"
          alt="Google Logo"
          class="w-5 h-5 mr-2"
        />
        Continue with Google
      </button>

      <button
        type="submit"
        class="btn btn-outline w-full hover:bg-gray-200 hover:text-black"
        @click="handleAppleLogin"
      >
        <img
          src="~/assets/icons/apple.svg"
          alt="Apple Logo"
          class="w-5 h-5 mr-2"
        />
        Continue with Apple
      </button>

      <button
        type="submit"
        class="btn btn-outline w-full hover:bg-gray-200 hover:text-black"
        @click="
          mode = 'email';
          clearErrors();
          focusOnEmail();
        "
      >
        <EnvelopeIcon class="w-5 h-5 mr-2" />
        Continue with Email
      </button>
    </div>

    <div
      v-if="mode == 'email'"
      class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-3"
    >
      <form
        class="space-y-6"
        action="#"
        method="POST"
        @submit.prevent="handleCheckEmail"
      >
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900"
            :class="{
              'text-red-500': emailError,
            }"
            >Email address</label
          >
          <div class="mt-2">
            <input
              id="email"
              ref="emailField"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="input input-bordered w-full"
              :class="{
                'input-error': emailError,
              }"
            />
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary w-full"
          :disabled="emailSignupPending"
        >
          Continue
        </button>

        <div v-if="socialLogin" class="flex items-center justify-between">
          <div class="flex-1 border-t border-gray-300" />
          <div class="px-3 text-sm leading-5 text-gray-500">or</div>
          <div class="flex-1 border-t border-gray-300" />
        </div>

        <div v-if="socialLogin" class="flex flex-col gap-y-2">
          <button
            type="submit"
            class="btn btn-outline w-full hover:bg-gray-200 hover:text-black"
            @click="handleGoogleLogin"
          >
            <img
              src="~/assets/icons/google.svg"
              alt="Google Logo"
              class="w-5 h-5 mr-2"
            />
            Continue with Google
          </button>

          <button
            type="submit"
            class="btn btn-outline w-full hover:bg-gray-200 hover:text-black"
            @click="handleAppleLogin"
          >
            <img
              src="~/assets/icons/apple.svg"
              alt="Apple Logo"
              class="w-5 h-5 mr-2"
            />
            Continue with Apple
          </button>
        </div>
      </form>
    </div>

    <div
      v-if="mode == 'emailSignup'"
      class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-3"
    >
      <form
        class="space-y-6"
        action="#"
        method="POST"
        @submit.prevent="handleCreateUserWithEmail"
      >
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900"
            :class="{
              'text-red-500': emailError,
            }"
            >Email address</label
          >
          <div class="mt-2">
            <input
              id="email"
              ref="emailField"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="input input-bordered w-full"
              :class="{
                'input-error': emailError,
              }"
            />
          </div>
        </div>

        <div>
          <label
            for="name"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Name</label
          >
          <div class="mt-2">
            <input
              id="name"
              ref="nameField"
              v-model="name"
              name="name"
              type="text"
              autocomplete="name"
              required=""
              class="input input-bordered w-full"
            />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label
              for="new-password"
              class="block text-sm font-medium leading-6 text-gray-900"
              :class="{
                'text-red-500': passwordError,
              }"
              >Password</label
            >
          </div>
          <div class="mt-2">
            <input
              id="new-password"
              ref="passwordField"
              v-model="password"
              name="new-password"
              type="password"
              autocomplete="new-password"
              required=""
              class="input input-bordered w-full"
              :class="{
                'input-error': passwordError,
              }"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="btn btn-primary w-full"
            :disabled="emailSignupPending"
          >
            Sign up
          </button>
        </div>
      </form>
    </div>

    <div
      v-if="mode == 'emailSignin'"
      class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-3"
    >
      <form
        class="space-y-6"
        action="#"
        method="POST"
        @submit.prevent="handleLoginWithEmail"
      >
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900"
            :class="{
              'text-red-500': emailError,
            }"
            >Email address</label
          >
          <div class="mt-2">
            <input
              id="email"
              ref="emailField"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="input input-bordered w-full"
              :class="{
                'input-error': emailError,
              }"
            />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label
              for="current-password"
              class="block text-sm font-medium leading-6 text-gray-900"
              :class="{
                'text-red-500': passwordError,
              }"
              >Password</label
            >
          </div>
          <div class="mt-2">
            <input
              id="current-password"
              ref="passwordField"
              v-model="password"
              name="current-password"
              type="password"
              autocomplete="current-password"
              required=""
              class="input input-bordered w-full"
              :class="{
                'input-error': passwordError,
              }"
            />
          </div>
          <div class="text-sm mt-2">
            <a
              href="#"
              class="font-semibold text-indigo-600 hover:text-indigo-500"
              @click="handleForgotPassword"
              >Forgot password?</a
            >
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="btn btn-primary w-full"
            :disabled="emailSignupPending"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import isWebview from 'is-ua-webview';

const emit = defineEmits(['close']);
const mode = ref('signin');
const props = defineProps(['authMode', 'fromAction']);
if (props.authMode) mode.value = props.authMode;

const socialLogin = ref(true);
onMounted(() => {
  const userAgent = navigator.userAgent;
  if (userAgent && isWebview(userAgent)) {
    socialLogin.value = false;
  }
  if (document.referrer && document.referrer.includes('instagram')) {
    socialLogin.value = false;
  }
  if (!socialLogin.value && mode.value === 'signup') {
    mode.value = 'email';
  }
});

const email = ref(null);
const password = ref(null);
const name = ref(null);
const emailField = ref(null);
const nameField = ref(null);
const passwordField = ref(null);
const loginMessage = ref(null);
const emailError = ref(false);
const passwordError = ref(false);
const emailSignupPending = ref(false);

const registerLogin = async () => {
  const firebaseUser = useFirebaseUser();
  const data = await $fetch('/api/login', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await firebaseUser.value?.getIdToken()}`,
    },
    body: JSON.stringify({
      name: name.value,
    }),
  });

  await useFetchUser();

  await handleProviderRedirect(data.user);
};

const handleGoogleLogin = async () => {
  try {
    await signInGoogle();
  } catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      loginMessage.value = {
        message:
          'Email already exists, please login with the method you used to sign up',
        type: 'error',
      };
      return;
    }

    if (
      e.code === 'auth/cancelled-popup-request' ||
      e.code === 'auth/popup-closed-by-user'
    ) {
      console.error('Google login popup closed', e);
      return;
    }

    console.error('Google login failed', e);
    loginMessage.value = {
      message: 'We are having trouble logging you in, please try again',
      type: 'error',
    };
    throw e;
  }
  await registerLogin();

  emit('close');
};

const handleProviderRedirect = async (user) => {
  // do not redirect to provider page if user came from a previous action that required login
  if (props.fromAction) return;

  if (user.provider) {
    await navigateTo(getProviderLink(user.provider));
  }
};

// const handleFacebookLogin = async () => {
//   try {
//     await signInFacebook()
//   } catch (e) {
//     if (e.code === 'auth/account-exists-with-different-credential') {
//       console.error('Account already exists with different credential')
//       useSetToast({
//         message: 'Email already exists, please login with the method you used to sign up',
//         type: 'error'
//       })
//     } else {
//       console.error('Facebook login failed', e)
//       useSetToast({
//         message: 'We are having trouble logging you in, please try again',
//         type: 'error'
//       })
//     }
//     throw e
//   }
//   await registerLogin()
//   emit('close')
// }
const handleAppleLogin = async () => {
  try {
    await signInApple();
  } catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      console.error('Account already exists with different credential');
      loginMessage.value = {
        message:
          'Email already exists, please login with the method you used to sign up',
        type: 'error',
      };
      return;
    }

    if (
      e.code === 'auth/cancelled-popup-request' ||
      e.code === 'auth/popup-closed-by-user'
    ) {
      console.error('Google login popup closed', e);
      return;
    }

    console.error('Apple login failed', e);
    loginMessage.value = {
      message: 'We are having trouble logging you in, please try again',
      type: 'error',
    };
    throw e;
  }
  await registerLogin();
  emit('close');
};

const handleCheckEmail = async () => {
  clearErrors();
  emailSignupPending.value = true;
  const authMethods = await checkEmailAuth(email.value);
  emailSignupPending.value = false;
  if (authMethods.length === 0) {
    // email does not exist
    mode.value = 'emailSignup';
    focusOnName();
  } else if (authMethods.includes('password')) {
    // email exists with password
    mode.value = 'emailSignin';
    focusOnPassword();
  } else {
    // email exists with social login
    loginMessage.value = {
      message: 'Email already exists with another sign in method',
      type: 'error',
    };
    emailError.value = true;
  }
};

const handleCreateUserWithEmail = async () => {
  clearErrors();
  emailSignupPending.value = true;
  let user;
  try {
    user = await createUser(email.value, password.value);
  } catch (e) {
    if (e.code === 'auth/email-already-in-use') {
      console.error('Account already exists');
      loginMessage.value = {
        message:
          'Email already exists, please login with the method you used to sign up',
        type: 'error',
      };
      mode.value = 'signup';
    } else if (e.code === 'auth/weak-password') {
      passwordError.value = true;
      loginMessage.value = {
        message: 'Password should be at least 6 characters',
        type: 'error',
      };
    } else {
      console.error('Register failed', e);
      loginMessage.value = {
        message:
          'We are having trouble creating your account, please try again',
        type: 'error',
      };
    }
    emailSignupPending.value = false;
    throw e;
  }

  try {
    await updateUserProfile(user.user, {
      displayName: name.value,
    });
    await registerLogin();
    await verifyEmail(user.user);
  } catch (e) {
    console.error(e);
    loginMessage.value = {
      message: 'We are having trouble creating your account, please try again',
      type: 'error',
    };
    mode.value = 'email';
    emailSignupPending.value = false;
    throw e;
  }

  emailSignupPending.value = false;
  emit('close');
  useSetToast({
    message: 'Welcome to MyProviders!',
    type: 'info',
  });
};

const focusOnEmail = () => {
  setTimeout(() => {
    emailField.value.focus();
  }, 100);
};

const focusOnPassword = () => {
  setTimeout(() => {
    passwordField.value.focus();
  }, 100);
};

const focusOnName = () => {
  setTimeout(() => {
    nameField.value.focus();
  }, 100);
};

const clearErrors = () => {
  loginMessage.value = null;
  emailError.value = false;
  passwordError.value = false;
};

const handleLoginWithEmail = async () => {
  clearErrors();
  emailSignupPending.value = true;
  try {
    await signInEmail(email.value, password.value);
  } catch (e) {
    if (e.code === 'auth/wrong-password') {
      loginMessage.value = {
        message: 'Incorrect password',
        type: 'error',
      };
      passwordError.value = true;
    } else if (e.code === 'auth/user-not-found') {
      console.error('User not found', e);
      loginMessage.value = {
        message: 'Account does not exist',
        type: 'error',
      };
    } else {
      console.error('Login with email failed', e);
      loginMessage.value = {
        message: 'We are having trouble logging you in, please try again',
        type: 'error',
      };
    }

    emailSignupPending.value = false;
    throw e;
  }

  try {
    await registerLogin();
  } catch (e) {
    loginMessage.value = {
      message: 'We are having trouble log you in, please try again',
      type: 'error',
    };
    emailSignupPending.value = false;
    throw e;
  }
  emailSignupPending.value = false;
  emit('close');
};

const handleForgotPassword = async () => {
  clearErrors();
  try {
    await resetPassword(email.value);
    loginMessage.value = {
      message: 'Password reset email sent',
      type: 'info',
    };
  } catch (e) {
    if (e.code === 'auth/user-not-found') {
      console.error('User not found', e);
      loginMessage.value = {
        message: 'Account does not exist',
        type: 'error',
      };
    }

    console.error('Forgot password failed', e);
    loginMessage.value = {
      message:
        'We are having trouble sending you a password reset email, please try again',
      type: 'error',
    };

    throw e;
  }
};

const isFirebaseUserLoading = useFirebaseUserLoading();

watch(
  () => isFirebaseUserLoading.value,
  async (newLoading, oldLoading) => {
    if (props.fromAction === 'loginPage') return;

    if (oldLoading && !newLoading) {
      const user = useUser().value;
      if (!user) return;

      emit('close');

      if (!user.provider) return;

      await navigateTo(getProviderLink(user.provider));
    }
  },
);
</script>
